<template>
  <div>
    <Breadcrumbs
      :breadcrumbs="breadcrumbs"
      class="mb-8"
    />

    <div class="bg-white p-6 shadow rounded-lg">
      <div
        class="lg:flex items-baseline justify-between"
      >
        <div
          v-if="!examsCompleted"
          class="lg:w-2/3"
        >
          <h1 class="text-2xl lg:text-3xl mb-3 font-medium">
            {{ job.name }} at {{ job.organisationName }}
          </h1>
          <h2 class="text-lg">
            Today, you'll be completing the assessments below
          </h2>
          <p class="text-sm text-gray-700 mt-2 lg:max-w-2xl">
            Please read the instructions carefully before starting each assessment.
          </p>
        </div>

        <div
          v-if="examsCompleted && !profileComplete"
          class="lg:w-2/3"
        >
          <h1 class="text-2xl lg:text-3xl mb-2 font-medium">
            Great!
          </h1>
          <p class="text-xl">
            Please <router-link
              :to="{ name: 'candidate-wizard-profile-edit', query: { job: jobId } }"
              class="underline font-bold"
            >
              update your profile
            </router-link> in order to submit your assessments to <strong>{{ job.organisationName }}.</strong>
          </p>
        </div>

        <span
          v-if="!examsCompletedAndSent"
          class="relative w-1/3 text-right"
        >
          <div v-if="!profileComplete && examsCompleted">
            You should
            <router-link
              :to="{ name: 'candidate-wizard-profile-edit' }"
              class="underline font-bold"
            >
              complete your profile
            </router-link>
            before submitting your assessments
          </div>
        </span>
      </div>

      <div v-if="examsCompleted">
        <div class="rounded-lg bg-green-50 p-4 w-full">
          <div class="flex items-baseline">
            <div class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white text-secondary bg-secondary flex-shrink-0">
              <CheckIsolated class="w-5 h-5 text-white"></CheckIsolated>
            </div>
            <div class="ml-3">
              <h3 class="text-3xl font-medium text-green-800 leading-10">
                Assessments Completed
              </h3>
              <div class="mt-2 text-green-700">
                <p>
                  You’ve completed and submitted all your assessments for <strong>{{ job.name }}</strong> at <strong>{{ job.organisationName }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul
        v-if="exams && exams.length > 0"
        class="grid lg:grid-cols-2 gap-6 mt-8"
      >
        <li
          v-for="(exam, index) in orderedExams"
          :key="index"
          class="border rounded-lg p-4 flex flex-col"
        >
          <div class="flex items-center justify-between text-2xl">
            <h2 class="font-bold">
              {{ exam.name }}
            </h2>
            <p
              v-if="exam.minutes"
              class="font-bold"
            >
              {{ exam.minutes }} <span class="text-sm">minutes</span>
            </p>
          </div>
          <p class="text-sm text-gray-700 mt-2 mb-6">
            {{ exam.description }}
          </p>

          <BaseButton
            v-if="!examCompleted(exam.slug) && !job.deadlineExpired"
            class="mt-auto self-start"
            :to="{
              name: 'candidate-wizard-assessment',
              params: {
                job: jobId,
                assessment: exam.slug
              }
            }"
          >
            <template slot="iconLeft">
              <Icon
                view-box="0 0 24 24"
                class="mr-2 w-4 h-4"
              >
                <Edit />
              </Icon>
            </template>
            Take test
          </BaseButton>

          <BaseButton
            v-if="examCompleted(exam.slug) && !job.deadlineExpired"
            class="mt-auto self-start text-gray-800"
            variant="gray"
            disabled
          >
            <template slot="iconLeft">
              <Icon
                view-box="0 0 24 24"
                class="mr-2 w-4 h-4"
              >
                <Check />
              </Icon>
            </template>
            Completed
          </BaseButton>

          <BaseButton
            v-if="job.deadlineExpired"
            class="mt-auto self-start text-gray-800"
            variant="gray"
            disabled
          >
            Deadline Expired
          </BaseButton>
        </li>
      </ul>
      <div
        v-else
        class="my-12"
      >
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@components/Breadcrumbs'
import candidateWizardApi from '@api/candidateWizard'
import Check from '@components/Icons/Check'
import CheckIsolated from '@components/Icons/Isolated/Check'
import Edit from '@components/Icons/Edit'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'

export default {
  components: {
    Breadcrumbs,
    Check,
    CheckIsolated,
    Edit,
    Icon,
    Loader
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    profileComplete: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      submissionError: null,
      examsCompletedAndSent: Boolean(this.candidate.finishedJobs && this.candidate.finishedJobs.length),
      exams: null,
      loading: false
    }
  },

  computed: {
    /**
     * @return {string}
     */
    submitButtonVariant() {
      return this.examsCompleted ? 'green' : 'inverse'
    },

    /**
     * @return {string}
     */
    jobId() {
      return this.$route.params.job
    },

    /**
     * @return {Object}
     */
    job() {
      return this.candidate.jobs.find(job => job.wizardSlug === this.jobId)
    },

    /**
     * @return {Array}
     */
    examSlugs() {
      if (!this.exams) {
        return []
      }
      return this.exams.map(exam => exam.slug)
    },

    /**
     * @return {Array}
     */
    orderedExams() {
      if (!this.exams) {
        return []
      }
      return this.exams.slice().sort((a, b) => a.name.localeCompare(b.name))
    },

    /**
     * @return {Array}
     */
    attemptExamSlugs() {
      if (!this.candidate.attempts.length) {
        return []
      }
      return this.candidate.attempts.map(attempt => attempt.examSlug)
    },

    /**
     * @return {Boolean}
     */
    examsCompleted() {
      if (!this.exams) {
        return false
      }
      const remainingExams = this.exams.filter(exam => {
        return !this.attemptExamSlugs.includes(exam.slug)
      })
      return remainingExams.length === 0
    },

    breadcrumbs() {
      return [
        {
          name: 'Dashboard',
          link: 'candidate-wizard-dashboard'
        },
        {
          name: this.job.name,
          link: 'candidate-wizard-job'
        }
      ]
    }
  },

  created() {
    this.fetchExams()
  },

  methods: {
    fetchExams() {
      if (!this.jobId) {
        return
      }

      return candidateWizardApi.job(this.jobId)
        .then(response => {
          this.exams = [...response.data.exams, ...response.data.customExams]
        })
        .catch(error => {
          throw error
        })
    },

    examCompleted(examSlug) {
      if (!this.candidate.attempts.length) {
        return false
      }
      return this.attemptExamSlugs.includes(examSlug)
    }
  }
}
</script>
